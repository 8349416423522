import * as React from 'react';
import { useEffect, useState} from 'react';

import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import ListSubheader from '@mui/material/ListSubheader';  
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
// import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// import LanguageIcon from '@mui/icons-material/Language';


import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


import Logo from 'assets/Pictures/logo.svg';
// import MenuBack from 'assets/Pictures/icon-back.svg';
// import MenuOpen from 'assets/Pictures/menu-open.svg';
// import MenuClose from 'assets/Pictures/menu-close.svg';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import styles from 'styles/navbar.module.scss';

import { isMobileOnly } from "react-device-detect";

import { useTranslation } from 'next-i18next';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

let i = 0;
function Navbar({addPWA,props}){


  const isMobile = isMobileOnly;

  const { t } = useTranslation('common');

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const router = useRouter();
  const { pathname, asPath, locale, query } = router;
  // console.log("router.pathname = "+router.pathname)
  // console.log(router.pathname.includes("hair-stylist"))

  useEffect(() => {
    const dir = locale!="en"?(locale!="en-SA"?"rtl":"ltr"):"ltr";
    document.querySelector("html").setAttribute("dir", dir);
  }, [locale]);

  const [ cookie, setCookie ] = useCookies(['NEXT_LOCALE'])

  const handleLanguageToggle = () => {
    console.log("handleLanguageToggle")
    console.log(locale);
    switch(locale){
      case "en":
        console.log("switch en");
        // router.push("/","/",{locale:"ar"})
        // router.push({pathname,query}, asPath, {locale:"ar"});
        setCookie("NEXT_LOCALE", "ar", { path: "/" });
        router.replace({
          route: router.pathname,
          query: router.query
        }, router.asPath,{locale:"ar"});
        break;
      case "ar":
        console.log("switch ar");
        // router.push("/","/",{locale:"en"})
        // router.push({pathname,query}, asPath, {locale:"en"});
        setCookie("NEXT_LOCALE", "en", { path: "/" });
        router.replace({
          route: router.pathname,
          query: router.query
        }, router.asPath,{locale:"en"});
        break;
      case "en-SA":
        console.log("switch en-SA");
        setCookie("NEXT_LOCALE", "ar-SA", { path: "/" });
        router.replace({
          route: router.pathname,
          query: router.query
        }, router.asPath,{locale:"ar-SA"});
        break;
      case "ar-SA":
        console.log("switch ar-SA");
        setCookie("NEXT_LOCALE", "en-SA", { path: "/" });
        router.replace({
          route: router.pathname,
          query: router.query
        }, router.asPath,{locale:"en-SA"});
        break;
    }
  }

  const [deferredPrompt, setdeferredPrompt] = useState();
  useEffect(() => {
    // console.log("useeffect for beforeinstallprompt");

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      if(!router.pathname.includes("hair-stylist")){
        e.preventDefault();
      }
      // Stash the event so it can be triggered later.
      // deferredPrompt = e;
      setdeferredPrompt(e)
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      // console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  useEffect(() => {


    if(addPWA!=undefined){
      console.log("PWA Prompt Now");
      const triggerPrompt = async () => {
         console.log("triggerPrompt async")
         // Show the install prompt
         deferredPrompt.prompt();
         // Wait for the user to respond to the prompt
         deferredPrompt.userChoice.then((choiceResult) => {
           if (choiceResult.outcome === 'accepted') {
             console.log('User accepted the A2HS prompt');
           } else {
             console.log('User dismissed the A2HS prompt');
           }
           deferredPrompt = null;
         });
       }
       triggerPrompt();
    }

  }, [addPWA]);

  const useWidth = () => {
    if (process.browser) {
      const [width, setWidth] = useState(window.innerWidth);
      const handleResize = () => setWidth(window.innerWidth);
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [width]);
      return width;
    }
    return 0;
  };
  const windowWidth = useWidth();
  const [gutter, setgutter] = useState();
  useEffect(() => {
    if (windowWidth < 480) {
      setgutter(false)
    } else {
      setgutter(false)
    }
  }, [windowWidth]);
  
  return(
    <div className={styles.navbar}>
      <Container maxWidth="lg" disableGutters={gutter}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}
          sx={{
            '& .MuiIconButton-root': { p: 0 },
          }}>
            <Grid item xs>
            <div className={styles.contents_menu_open}>
              <IconButton color="primary" onClick={handleDrawerOpen} aria-label={t('menu')}>
                <MenuRoundedIcon fontSize="large"/>
              </IconButton>
              <span className={styles.text}>{t('menu')}</span>
            </div>
          </Grid>
          <Grid item xs >
            <div className={styles.contents_logo}>
              <Link href={router.pathname.includes("hair-stylist") ? "/hair-stylist":"/"} className={`${styles.link}`}>
                <span className={styles.logo_wr}>
                  <Logo className={styles.logo}/>
                </span>
              </Link>
            </div>
          </Grid>
          <Grid item xs>
            <div className={styles.contents_lang}>
              <Button 
                variant="text" size="large"
                sx={{px:1,minWidth:"0"}} 
                onClick={handleLanguageToggle}>
                  {locale=="ar" &&
                    <span className='en'>English</span>
                  }
                  {locale=="en" &&
                    <span className='ar'>العربية</span>
                  }
              </Button>
            </div>
          </Grid>
          
          
        </Grid>
      </Container>

      <Drawer
        className={styles.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClick={handleDrawerClose}
        component="nav"
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
          },
        }}
      >
        <DrawerHeader className={styles.close_btn_wr}>
          <IconButton onClick={handleDrawerClose} className={styles.close_btn}>
            <CloseIcon fontSize="large"/>
          </IconButton>
        </DrawerHeader>
        <List
          className={styles.menu}
          component="ul"
          // subheader={
          //   <ListSubheader component="span" className={styles.listhead} component="li" onClick={handleLanguageToggle}>
          //     <LanguageIcon/>
          //     {locale=="en"? <span>العربية</span> : <span>English</span>}
              
          //   </ListSubheader>
          // }
          sx={{
            m:0,
            p:0
          }}>
          {/* <ListItem button className={`${styles.link}`} component="li" onClick={handleLanguageToggle}>
            <ListItemText className={styles.text} primary={
              <React.Fragment >
                <span className={styles.text_primary_lang}>
                  <LanguageIcon/>
                  {locale!="en"? (locale!="en-SA"?<span>English</span> : <span>العربية</span>): <span>العربية</span>}
                </span>
              </React.Fragment>
            }/>
          </ListItem> */}
          {/* <Link href="/" passHref scroll={false}>
            <ListItem button className={`${styles.link} ${router.pathname == "/" ? "active" : ""}`} component="li">
              <ListItemText className={styles.text} primary={
                <React.Fragment >
                  <span className={styles.text_secondary}>
                    {t('lifeshine_collection')}
                  </span>
                </React.Fragment>
              }/>
            </ListItem>
          </Link> */}
          {/* <Link href="/ultimatt-collection" passHref scroll={false}>
            <ListItem button className={`${styles.link} ${router.pathname == "/ultimatt-collection" ? "active" : ""}`} component="li">
              <ListItemText className={styles.text} primary={
                <React.Fragment >
                  <span className={styles.text_secondary}>
                    {t('utimat_collection')}
                  </span>
                </React.Fragment>
              }/>
            </ListItem>
          </Link> */}
          <Link href="/" passHref scroll={false}>
            <ListItem button className={`${styles.link} ${router.pathname == "/" ? "active" : ""}`} component="li">
              <ListItemText className={styles.text} primary={
                <React.Fragment >
                  <span className={styles.text_secondary}>
                    {t('wonderwall_lux')}
                  </span>
                </React.Fragment>
              }/>
            </ListItem>
          </Link>
          <Link href="/jotashield-nuovo" passHref scroll={false}>
            <ListItem button className={`${styles.link} ${router.pathname == "/jotashield-nuovo" ? "active" : ""}`} component="li">
              <ListItemText className={styles.text} primary={
                <React.Fragment >
                  <span className={styles.text_secondary}>
                    {t('jotashield_nuovo')}
                  </span>
                </React.Fragment>
              }/>
            </ListItem>
          </Link>
          
          
          <ListItem className={styles.link} component="li" sx={{
            "display":"flex",
            alignItems:"center",
            justifyContent:"center",
            "& .MuiButtonBase-root":{
              mx:1
            }
          }}>
            <Link href="https://www.linkedin.com/company/jotunmeia/" passHref >
              <a target="_blank" rel="noopener noreferrer">
                <IconButton aria-label="facebook" target="_blank" >
                  <LinkedInIcon fontSize="large"/>
                </IconButton>
              </a>
            </Link>
            {/* <Link href="https://www.instagram.com/" passHref >
              <IconButton aria-label="instagram" target="_blank" >
                <InstagramIcon fontSize="large"/>
              </IconButton>
            </Link>
            <Link href="https://twitter.com/" passHref >
              <IconButton aria-label="twitter" target="_blank" >
                <TwitterIcon fontSize="large"/>
              </IconButton>
            </Link> */}
          </ListItem>
        </List>
        
      </Drawer>
    </div>
  )
}
export default Navbar;
